
import axios from 'axios';
import _ from 'lodash';
import * as Sentry from "@sentry/react";
import {
    useQuery
  } from '@tanstack/react-query';
import spinner from './images/crystal-rotating-spinner.svg';

export default function ValidationUI({context}){

    console.log(context)

    const is_debug_mode = false;

    var parent_url = (window.location !== window.parent.location)
        ? document.referrer
        : document.location.href;

    console.log("parent_url", parent_url)


    const validationQuery = (context) => {

        let url = `https://voyager.app.n8n.cloud/webhook/5c350649-e377-4bd5-8360-1bff5e077a8e?env_id=${context.env_id}&`;
        if(context.view === 'change_events.detail' || context.view === 'change_events.edit')
            url += `CHANGE_EVENT_ID=${context.id}`;
      
        if(context.view === 'prime_contracts.change_orders.detail' || context.view === 'prime_contracts.change_orders.potential_change_orders.detail' || context.view === 'prime_contracts.change_orders.potential_change_orders.edit')
            url += `PRIME_CHANGE_ORDER_ID=${context.id}`;

        if(context.view === 'commitments.work_order_contracts.detail')
            url += `WORK_ORDER_CONTRACT_ID=${context.id}`;

        return axios.get(url, {
            headers: {
              "accept": "application/json",
              "content-type": "application/json",
            }
          });
    }

    const { error, data, isFetching } = useQuery({ 
        queryKey: ['validations'], 
        queryFn: () => validationQuery(context),
        refetchInterval: 45000
    })

    if(context.route === '/:project_id/project/prime_contracts/:prime_contract_id/change_orders/change_order_packages/:id'){
        return (
            <div style={{margin: "20px auto", width: "85%"}}>
                <h3>PCCOs</h3>
                <p>PCCOs are not currently processed through the SAP validation rules.</p>
                <p>View the individual Change Events that comprise the PCCOs for validations.</p>
            </div>
        );
    }

    if(isFetching && _.isEmpty(data)){
        return (<>
            <div className="loading">
                <img src={spinner} style={{display: 'block', marginTop: 40, marginLeft: 'auto',  marginRight: 'auto', width: '25%'}} alt="Loading..." />
                <div style={{textAlign: 'center', marginTop: 20}}>Loading...</div>
            </div>
        </>);
    }else if(_.isEmpty(data)){
        return (<>
            <h5 className="my-5">These line items are not currently processed through the SAP validation rules.<br /><br />Make sure the program for this project is "SAP Integrated," then try again later by refreshing this page(in about 15 minutes).</h5>
        </>);
    }

    if (error){
        Sentry.captureException(error); 

        // return (
        //     <div className="p-4">
        //         <br /><br />
        //         <p>We're sorry, an error has occurred retreiving validations from the database. Please refresh the page to try again.</p>
        //     </div>
        // )
    }

    console.log("data", data);

    const validationsResponse = data;
    const validationItems = validationsResponse.data.data;

    const invalidLineItems = _.filter(validationItems, (item) => {
        return item.ERROR_MESSAGE !== "VALID";
    });

    // console.log("invalidLineItems", invalidLineItems)

    let invalidLineItemsByType = _.groupBy(invalidLineItems, 'VALIDATION_TYPE');
    invalidLineItemsByType = Object.fromEntries(Object.entries(invalidLineItemsByType).sort());
    // console.log("invalidLineItemsByType", invalidLineItemsByType);


    if(context.view === 'commitments.work_order_contracts.detail'){

        const invalid_ce_items = validationsResponse.data.data.filter((item) => {
            return item.CCO_APPROVAL_CHANGED === 'true' && 
            item.ERROR_MESSAGE !== 'VALID' && 
            item.VALIDATION_TYPE === 'cco_approval_changed';
        });

        const invalid_ccos = _.uniqBy(invalid_ce_items, 'FORM_7_NUMBER');

        console.log("Invalid CCOs", invalid_ccos);

        if(invalid_ccos.length === 0){
            return (
                <>
                    <br />
                    <h3>No issues to be addressed</h3>
                </>
            )
        }

        // IF there are CCOs that are not approved.

        return (
            <>
                <br />
                <h3>CCOs with Invalid Statuses</h3>
                <p><em>When a CCO's status has been "Approved" by completing a CCO Workflow, the status should <strong>NOT</strong> be modified. Changing the status will cause errors in SAP/Textura because funds may have been billed for already. If an edit to the overall value is needed after the status has been set to "Approved", create new line items in the Change Event and create a new CCO document. The net value of the two CCOs combined should represent the total increase/decrease to the Trade Partner's overall contract value.</em></p>
                {Object.entries(invalid_ccos).map(
                ([k, v], i) => {

                    return (
                        <div className="alert alert-danger">
                            <h3>CCO: {v.FORM_7_NUMBER}</h3>
                            <p>The status of this CCO was <span className="badge rounded-pill text-bg-secondary">approved</span> but has since changed. Please complete the CCO Workflow ASAP and refer to the notes above for steps to make adjustments to overall CCO value if needed.</p>
                        </div>
                        
                    );

                })}
                <div className="row">
                    <div className="col-auto">

                    </div>
                </div>
            </>
        )
    }


    const valid = invalidLineItems.length || _.isEmpty(validationItems) ? false : true;
    const synced_to_snowflake = !_.isEmpty(validationItems);
    

    return (
        <div>
            
            <div className="row mb-2 justify-content-between">
                <div className="col-auto">
                    {isFetching && 
                        <>
                        Refreshing... <img style={{height: 22}} src={spinner} alt="loading" />
                        </>
                        
                    } 
                </div>
                <div className="col-auto">
                    <a href={`mailto:support@voyager-scientific.com.hs-inbox.com?subject=Clark%20Side%20Panel%20Feedback%20-%20${context.view}:${context.id}&body=%0A%0A%0A---------------------------------%0A%0A${context.view}:${context.id}`} rel="noreferrer" target="_blank">Submit Feedback</a>
                </div>
            </div>
            <div className={`row justify-content-between mx-0 mb-3 status_alert ${valid ? 'valid': 'invalid'}`} style={{lineHeight: 1}}>
                <div className="col-7 ps-2 pr-5 py-2 me-3">
                    <span id="SAP_status_message">
                    <strong>{valid ? 'Valid': 'Invalid'}</strong> {valid ?  ' with SAP. Validated data is synced with SAP within an hour.' : !synced_to_snowflake ? 'Please wait: Validations not yet run.' : 'Not valid for SAP due to the validation errors below.'}
                    
                    </span>
                </div>
                <div className="col-auto">
                    <div className="row justify-content-end">
                        <div className="col-auto px-1" style={{paddingTop: 12}}>
                            <span id="SAP_warning">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                            </span>
                        </div>
                        <div className="col-auto px-0 me-2" style={{paddingTop: 7}}>
                            <img alt="SAP" align='right' src='https://app.responsevault.com/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcVlsIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--e7d16a99d110a46dd069ed80b85754b19e8af392/Sap-Logo--1024x1024.png' style={{width: 58}} className='fr-fic fr-dib fr-fir' name='Sap-Logo--1024x1024.png' />
                        </div>
                    </div>
                </div>
                
            </div>

            {_.isEmpty(validationItems) ?
            <h5 className="my-5">These line items are not currently processed through the SAP validation rules.<br /><br />Make sure the program for this project is "SAP Integrated," then try again later by refreshing this page(in about 15 minutes).</h5>
            : null}

            {valid || _.isEmpty(validationItems)? null: <h5 className="pt-2 pb-0 mb-0" style={{fontSize: 14, color: "#333"}}>Validation errors that need to be addressed:</h5>}
            
            {!valid && !_.isEmpty(validationItems) ? <h4 className="mb-1 mt-3">Change Event(s)</h4> : null}
            
            { !valid && !_.isEmpty(validationItems) &&
            Object.entries(invalidLineItemsByType).map(
                ([k, v], i) => {
                return (
                    <div key={i} className="warnings_container">
                        <div key={k} className='alert alert-warning'>
                        <h6 style={{color: "#000"}}><strong>{_.startCase(k).replace(/pco/i, function(a) {return a.toUpperCase() })}</strong></h6>
                        <p style={{color: "#555"}}>{v[0].ERROR_MESSAGE}</p>

                        <h6 style={{color: "black", fontSize: 11, marginTop: 15, fontWeight: "bold"}}>Items affected:</h6>
                        {_.map(_.sortBy(v, "CHANGE_EVENT_LINE_ITEM_ID"), (item) => {
                            if(item.ERROR_MESSAGE !== "VALID"){
                                return (
                                    
                                    <div className={`debug_container pb-2`}>
                                        <span style={{color: "black", fontSize: 11}}><strong>#{item.MOST_RECENT_NUMBER} - {item.DETAIL_DESCRIPTION || "Blank"}</strong></span><br />
                                        <table className={`debug_table ${is_debug_mode ? '' : 'd-none'}`}>
                                            <tbody>
                                                <tr><td>CHANGE_EVENT_ID: </td><td><a href={`${parent_url}${context.project_id}/project/change_events/events/${item.CHANGE_EVENT_ID}`} target="_blank" rel="noreferrer">{item.CHANGE_EVENT_ID}</a></td></tr>
                                                <tr><td>CHANGE_EVENT_LINE_ITEM_ID: </td><td>{item.CHANGE_EVENT_LINE_ITEM_ID}</td></tr>
                                                <tr>
                                                    <td>PRIME_CHANGE_ORDER_ID: </td>
                                                    <td>
                                                    {item.PRIME_CHANGE_ORDER_ID ? <a href={`${parent_url}${context.project_id}/project/prime_contracts/${item.PRIME_CONTRACT_ID}/change_orders/potential_change_orders/${item.PRIME_CHANGE_ORDER_ID}?subtab=general_settings`} target="_blank" rel="noreferrer">{item.PRIME_CHANGE_ORDER_ID}</a> : "N/A"}
                                                    
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                
                                );
                            }
                        })}
                        </div>
                    </div>
                )
                }
            )
            }

        <br />
      </div>
    )
}